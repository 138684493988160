@charset "utf-8";
/* ============================================================ */
/* privacy */
/* ============================================================ */
.privacy {
	.privacy-text {
		margin: 0 0 30px;
	}
	
	.privacy-block {
		margin: 0 0 40px;
		
		&:last-of-type {
			margin: 0;
		}
		
		&__contact {
			margin: 15px 0 0;
			padding: 15px;			
		}
	}
}