@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: $textColor;
	font-size: $l;
	line-height: 1.7;
	font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
	overflow: hidden;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	font-weight: bold;
	
	&:hover {
		text-decoration: none;
	}
}

.link-opacity {
	@include transition;
	
	&:hover {
		@include opacity;
	}
}

a[href^="tel:"] {
	cursor: default;
	color: $textColor;
	text-decoration: none;
	
	&:hover {
		opacity: 1.0;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 50px;
	font-size: $s;
	line-height: 1.4;
	position: relative;
	z-index: 2;
	font-weight: bold;

	&::before {
		background: url("/common/img/common/bg_contents.png") top center repeat-x;
		background-size: 100%;
		width: 100%;
		height: 500px;
		top: 0;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;
		z-index: 1;
		opacity: 0.9;
	}
	
	&__block {
		position: relative;
		z-index: 2;
		border-bottom: 1px dotted #bfb7aa;
		padding: 15px 0;
		background: rgba($white, 0.7);
		
		&__inner {
			display: flex;
			flex-wrap: wrap;
			max-width: 1200px;
			margin: 0 auto;
			position: relative;
			z-index: 3;
			
			@media screen and (max-width: 1275px) {
				padding: 0 30px;
			}
			
			&__item {
				&:last-child {
					_:-ms-fullscreen, :root & {
						padding: 3px 0 0;
					}
				}

				&:not(:last-child) {
					&::after {
						margin: 0 10px;
						content: "\f105";
						font-family: 'icomoon';
						color: #b7a992;
						font-weight: normal;
					}
				}
			}	
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 100;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		background: rgba($white, 0.9);
		border: 2px solid $baseColor;
		color: $baseColor;
		font-size: 3.6rem;
		line-height: 1.0;
		text-decoration: none;
		@include radius(5);
		@include transition;
		transition: 0.3s all;
		overflow: hidden;
		transform: perspective(1px) translateZ(0);
		transition-property: color;
		transition-duration: 0.3s;
		
		&::before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: scaleX(0);
			transform-origin: 0 50%;
			transition-property: transform;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
			background: $baseColor;
		}

		&:hover::before, 
		&:focus::before, 
		&:active::before {
			transform: scaleX(1);
			color: $white !important;
		}

		&:hover {
			color: $white !important;
		}
	}
}