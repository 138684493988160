@charset "utf-8";

$baseColor: #02503D;
$textColor: #40220F;
$borderColor: #E1D9CB;

$white: #fff;
$beige: #FCDEBE;
$yellow: #EDAE0F;
$pink: #E18080;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin box-shadow {
	 box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
 }

@keyframes menufade {
	from {
		opacity: 0.8;
		-webkit-transform: translateY(-100px);
		-moz-transform: translateY(-100px);
		transform: translateY(-100px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		transform: translateY(0px)
	}
}


/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

