@charset "utf-8";
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	.contents {
		&__inner {
			.ttl02 {
				margin: 0 0 30px;
				
				&--information {
					background: url("/common/img/about/bg_ttl_information.png") top center no-repeat;
				}
				
				&--facilities {
					background: url("/common/img/about/bg_ttl_facilities.png") top center no-repeat;
				}
			}
		}
	}
	
	.about-ank {
		margin-bottom: 70px;
		.btn {
			min-width: auto;
			width: 100%;
		}
	}
	
	.about-block {
		margin: 0 0 80px;
		
		&:last-of-type {
			margin: 0;
		}
	}
	
	#concept {
		text-align: center;
		
		.ttl06 {
			text-align: center;
			font-size: $xxl;
			margin: 0 0 30px;
		}
		
		.concept-text {
			margin: 0 0 50px;
		}
		
		.concept-img {
			justify-content: space-between;
			margin: 0 0 80px;
			
			&__item {
				margin: 0;
				
				&:first-of-type {
					width: 45%;
				}
				
				&:last-of-type {
					width: 51.5%;
				}
			}
		}
		
		.concept-dog {
			position: relative;
			padding: 60px 0 50px;
			
			&::before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -355px;
				z-index: -1;
				content: '';
				width: 2000px;
				background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
			}
			
			.ttl03 {
				color: $white;
				
				&::after {
					border-bottom: 2px solid rgba($white, 0.5);
				}
			}
			
			&__text {
				margin: 0 0 40px;
				color: $white;
			}
			
			&__staff {
				display: flex;
				justify-content: space-between;
				margin: 0 -10px;
				
				&__item {
					margin: 0 10px 40px;
					padding: 30px 20px;
					width: calc((100% - 101px) / 5 );
					background: $white;
					@include box-shadow;
					@include radius(5);
					
					&__img {
						margin: 0 0 20px;
					}
					
					&__text {
						font-weight: bold;
						
						&__name {
							margin: 0 0 5px;
						}
						
						&__detail {
							font-size: 1.5rem;
							text-align: left;
						}
					}
				}
			}
		}
	}
	
	#information {
		margin: 0 0 100px;
		
		.information-img {
			max-width: 650px;
			margin: 0 auto 40px;
		}
		
		.information-table {
			display: flex;
			justify-content: space-between;
			margin: 0 -15px;
			
			&__item {
				 margin-left: 15px;
				 margin-right: 15px;
				 width: calc((100% - 61px) / 2 );
				
				.table {
					.bg-green {
						width: 40%;
					}
					
					a[href^="tel:"] {
						font-weight: normal;
					}
					
					.table {
						.list {
							& > * {
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	#facilities {
		.facilities-inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;

			&__item {
				margin: 0 15px 40px;
				width: calc((100% - 91px) / 3 );
				background: url("/common/img/index/bg_about_inner.png") right bottom no-repeat $white;
				background-size: 153px;
				@include box-shadow;
				color: $textColor;

				&__img {
				}
				
				.ttl06 {
					text-align: center;
					font-size: $xl;
				}

				&__text {
					padding: 30px 30px 40px;
				}
			}
		}
	}
}