@charset "utf-8";
/* ============================================================ */
/* terms */
/* ============================================================ */
.terms {
	.ttl02 {
		&--belongings {
			background: url("/common/img/terms/bg_ttl_belongings.png") top center no-repeat;
		}
		&--time {
			background: url("/common/img/terms/bg_ttl_time.png") top center no-repeat;
		}
		&--attention {
			background: url("/common/img/terms/bg_ttl_attention.png") top center no-repeat;
		}
	}
	
	.terms-ank {
		margin-bottom: 80px;
		
		.btn {
			min-width: auto;
			width: 100%;
		}
	}
	
	.terms-block {
		margin: 0 0 100px;
	}
	
	.terms-flow {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		
		&__item {
			margin: 0 15px 30px;
			width: calc((100% - 61px) / 2 );
			
			.ttl03 {
				font-size: $xxxl;
			}
			
			.ttl06 {
				margin-top: 30px;
			}
			
			&__btn {
				text-align: center;
				margin: 30px 0 0;
				
				&__ico {
					margin: 0 10px 0 0;
					
					_:-ms-fullscreen, :root & {
						margin: -7px 10px 0 0;
					}
				}
				
				.btn {
					width: 90%;
					min-width: auto;
				}
			}
		}
	}
	
	.terms-list {
		list-style-type: none;
		counter-reset: number;
		
		&__item {
			position: relative;
			padding: 0 0 0 35px;
			margin: 0 0 10px;

			&::before {
				left: 0;
				position: absolute;
				counter-increment: number 1;
				content: "※" counter(number) " ";
				display: block;
				font-weight: bold;
			}
		}
	}
	
	#belongings {
		.box {
			max-width: 950px;
			margin: 0 auto 30px;
		}
		
		.list {
			margin-bottom: 0;
			
			.text-sup {
				font-size: $s;
				vertical-align: top;
			}
		}
	}
	
	.terms-time {
		max-width: 950px;
		margin: 0 auto 30px;

		&__tel {
			text-align: center;
			font-weight: bold;
			font-size: $xxxl;
		}
	}

	.terms-komelist {
		&__item {
		}
	}

	.terms-attention {
		margin: 0 0 60px;
		
		&__text {
			margin: 0 0 30px;
		}
	}
}