@charset "utf-8";
/* ============================================================ */
/* price */
/* ============================================================ */
.price {
	.ttl02 {
		&--option {
			background: url("/common/img/price/bg_ttl_option.png") top center no-repeat;
			background-size: 120px;
		}
	}
	
	.price-block {
		margin: 0 0 40px;
	}
	
	.table-wrap {
		margin: 0 0 20px;
	}
	
	.price-table {
		text-align: center;
		font-weight: bold;
		
		&__text {
			font-size: $xs;
		}
	}
	
	.price-list {
		&__item {
			margin: 0 0 10px;
		}
	}
	
	.price-option {		
		&__item {
			margin: 0 0 20px;
			
			&__img {
				margin: 0 0 20px;
			}
			
			.list {
				font-weight: bold;
				color: $baseColor;
				margin: 0 0 15px;
			}
		}
	}
}