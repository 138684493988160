@charset "utf-8";
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	.contents {
		&__inner {
			.ttl02 {
				&--information {
					background: url("/common/img/about/bg_ttl_information.png") top center no-repeat;
					background-size: 120px;
				}
				
				&--facilities {
					background: url("/common/img/about/bg_ttl_facilities.png") top center no-repeat;
					background-size: 120px;
				}
			}
		}
	}
	
	.about-ank {
		margin-bottom: 30px;
		
		.btn {
			min-width: auto;
			width: 100%;
			margin: 0;
		}
	}
	
	.about-block {
		margin: 0 0 40px;
		
		&:last-of-type {
			margin: 0;
		}
	}
	
	#concept {
		.ttl06 {
			text-align: center;
			font-size: $l;
			margin: 0 0 15px;
		}
		
		.concept-text {
			margin: 0 0 25px;
		}
		
		.concept-img {
			justify-content: space-between;
			margin: 0 0 40px;
			
			&__item {
				margin: 0;
				
				&:first-of-type {
					width: 45%;
				}
				
				&:last-of-type {
					width: 51.5%;
				}
			}
		}
		
		.concept-dog {
			position: relative;
			padding: 30px 0 25px;
			
			&::before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -355px;
				z-index: -1;
				content: '';
				width: 2000px;
				background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
			}
			
			.ttl03 {
				color: $white;
				
				&::after {
					border-bottom: 2px solid rgba($white, 0.5);
				}
			}
			
			&__text {
				margin: 0 0 25px;
				color: $white;
			}
			
			&__staff {
				&__item {
					display: flex;
					justify-content: space-between;
					padding: 15px;
					margin: 0 0 15px;
					background: $white;
					@include box-shadow;
					@include radius(5);
					
					&__img {
						width: 30%;
					}
					
					&__text {
						width: 65%;
						display: flex;
						justify-content: center;
						flex-direction: column;
						
						&__name {
							margin: 0 0 5px;
							font-weight: bold;
						}
						
						&__detail {
							font-size: $s;
						}
					}
				}
			}
		}
	}
	
	#information {
		margin: 0 0 50px;
		
		.information-img {
			margin: 0 0 20px;
		}
		
		.information-table {			
			&__item {
				.table {
					.list {
						& > * {
							margin-bottom: 5px;
						}
					}
				}
			}
		}
	}
	
	#facilities {
		.facilities-inner {
			&__item {
				margin: 0 0 20px;
				background: url("/common/img/index/bg_about_inner.png") right bottom no-repeat $white;
				background-size: 153px;
				@include box-shadow;
				color: $textColor;

				&__img {
				}
				
				.ttl06 {
					text-align: center;
					margin: 0 0 5px;
					font-size: $m;
				}

				&__text {
					padding: 15px 15px 20px;
				}
			}
		}
	}
}