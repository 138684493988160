@charset "utf-8";
/* ============================================================ */
/* privacy */
/* ============================================================ */
.privacy {
	.privacy-text {
		margin: 0 0 60px;
	}
	
	.privacy-block {
		margin: 0 0 80px;
		
		&:last-of-type {
			margin: 0;
		}
		
		&__contact {
			max-width: 700px;
			margin: 30px auto 0;
			padding: 30px;
			text-align: center;
			
			.ttl05 {
				padding: 0;
				margin: 0 -45px 15px 0;
				display: inline-flex;
				
				&::before {
					left: -45px !important;
					display: inline-block;
				}
			}
		}
	}
}