@charset "utf-8";
/* ============================================================ */
/* service */
/* ============================================================ */
.service {
	.contents {
		&__inner {
			.ttl02 {
				margin: 0 0 20px;
			}
		}
	}
	
	.service-ank {
		margin-bottom: 70px;
		margin-left: -15px;
		margin-right: -15px;

		 & > * {
			 margin-left: 15px;
			 margin-right: 15px;
			 width: calc((100% - 61px) / 2 );
		 }
		
		.btn {
			min-width: auto;
			width: 100%;
		}
	}
	
	.service-block {
		margin: 40px 0 70px;
		
		&:last-of-type {
			margin: 0;
		}
		
		.ttl03 {
			margin: 0 0 60px;
		}
	}
	
	.tokucho-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
        justify-content: center;
		
		&__item {
			margin: 0 15px 40px;
			width: calc((100% - 91px) / 3 );
			background: url("/common/img/index/bg_about_inner.png") right bottom no-repeat $white;
			background-size: 153px;
			@include box-shadow;
			color: $textColor;
			
			&__img {
			}
			
			&__text {
				padding: 30px 30px 40px;
			}
			
			.ttl06 {
				font-size: $xl;
				text-align: center;
				
				@media screen and (max-width: 1160px) {
					br {
						display: none;
					}
				}
			}
		}
	}
	
	.stay-block {
		margin-bottom: 0;
		margin-left: -25px;
		margin-right: -25px;

		 &__item {
			 margin-left: 25px;
			 margin-right: 25px;
			 width: calc((100% - 101px) / 2 );
			 
			 &__img {
				 margin: 0 0 40px;
			 }
			 
			 &__flow {
				 margin: 0 0 20px;
				 
				 &__inner {
					 border: 3px solid $baseColor;
					 padding: 15px 20px;
					 margin: 0 0 17px;
					 @include radius(5);
					 font-weight: bold;
					 text-align: center;
					 position: relative;
					 font-size: $xl;
					 
					 _:-ms-fullscreen, :root & {
							padding: 20px 20px 10px;
					 }
					 
					 &:last-of-type {
						 &::after {
							 display: none;
						 }
					 }
					 
					 &::after {
							top: 100%;
							left: 50%;
							border: solid transparent;
							content: '';
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-top-color: $baseColor;
							border-width: 20px;
							margin-left: -20px;
					 }
					 
					 &__text {
						 &__small {
							 font-size: $m;
						 }
					 }
				 }
			 }
		 }
	}

}