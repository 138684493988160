@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
}

body {
	&.fixed {
		position: fixed;
	}
}

.wrap {
	color: $textColor;
	font-size: 1.5rem;
	line-height: 1.7;
	font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
	overflow: hidden;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	font-weight: bold;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 25px;
	font-size: $xxs;
	line-height: 1.4;
	position: relative;
	z-index: 2;
	font-weight: bold;

	&::before {
		background: url("/common/img/common/bg_contents.png") top center repeat-x;
		background-size: 150%;
		width: 100%;
		height: 300px;
		top: 0;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;
		z-index: 1;
	}
	
	&__block {
		position: relative;
		z-index: 2;
		border-bottom: 1px dotted #bfb7aa;
		padding: 15px 0;
		background: rgba($white, 0.7);
		
		&__inner {
			display: flex;
			flex-wrap: wrap;
			padding: 0 15px;
			position: relative;
			z-index: 3;
			
			&__item {
				&:last-child {
					_:-ms-fullscreen, :root & {
						padding: 3px 0 0;
					}
				}
				
				&:not(:last-child) {
					&::after {
						margin: 0 7px;
						content: "\f105";
						font-family: 'icomoon';
						color: #b7a992;
						font-weight: normal;
					}
				}
			}	
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 100;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($white, 0.9);
		border: 2px solid $baseColor;
		color: $baseColor;
		font-size: 2.8rem;
		text-decoration: none;
		@include radius(5);
		line-height: 1.0;
	}
}