@charset "utf-8";
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	.ttl02 {
		&--form {
			background: url("/common/img/contact/bg_ttl_form.png") top center no-repeat;
		}
	}
	
	.contact-block {
		margin: 0 0 90px;
		
		&:last-of-type {
			margin: 0;
		}
	}
	
	.contact-text {
		margin: 0 0 50px;
	}
	
	.contact-tel {
		&__box {
			max-width: 800px;
			margin: 0 auto;
			text-align: center;
			padding: 25px;
			font-weight: bold;
			
			&__text {
				margin: 0;
				color: $baseColor;
				font-size: $xxxl;
				
				&__link {
					color: $baseColor;
					font-size: 4.0rem;
				}
				
				&__mobile {
					color: $baseColor;
				}
			}
		}
	}
	
	.contact-form {
		.list {
			margin: 0 0 40px;
		}
		
		&__table {
			margin: 40px 0;
			
			&__th {
				width: 30%;
				
				&__text {
					font-size: 1.5rem;
					font-weight: normal;
					display: block;
					margin: 5px 0 0;
				}
			}
			
			&__td {
				&__input {
					width: 60%;
					
					&--wide {
						width: 90%;
					}
				}
				
				&__dog {
					width: 40%;
				}
				
				&__select {
					width: 60%;
				}
				
				&__text {
					margin: 0 0 10px;
				}
			}
			
			.bg-beige {
				width: 15%;
				font-weight: bold;
				
				@media screen and (max-width: 1185px) {
					width: 20%;
				}
			}
		}
		
		.table-dog {
			&__item {
				margin: 0 0 10px;
				
				&:last-of-type {
					margin: 0;
				}
				
				&__text {
					font-weight: bold;
				}
			}
		}
	}
	
	.confirm-btn {
		@media screen and (max-width: 825px) {
			margin: 0 -15px;
			width: 100%;
		}
		
		.btn {
			margin: 0 15px;
			
			@media screen and (max-width: 825px) {
				min-width: 300px;
			}
		}
	}	
	
	.ui-datepicker {
		font-size: $l;
		line-height: 1.8;
	}
}

#contact_complete {
	.box {
		margin: 0 auto 40px;
		max-width: 1000px;
	}
	
	.list {
		margin-bottom: 0;
	}
}
