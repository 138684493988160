@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?e845v6');
  src:  url('/common/icon/fonts/icomoon.eot?e845v6#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?e845v6') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?e845v6') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?e845v6') format('woff'),
    url('/common/icon/fonts/icomoon.svg?e845v6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-dog-foot:before {
  content: "\e900";
}
.icon-mail:before {
  content: "\e901";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-search:before {
  content: "\f002";
}
.icon-check:before {
  content: "\f00c";
}
.icon-home:before {
  content: "\f015";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-phone:before {
  content: "\f095";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-close:before {
  content: "\e903";
}
.icon-menu:before {
  content: "\e902";
}
