@charset "utf-8";
/* ============================================================ */
/* terms */
/* ============================================================ */
.terms {
	.ttl02 {
		&--belongings {
			background: url("/common/img/terms/bg_ttl_belongings.png") top center no-repeat;
			background-size: 120px;
		}
		&--time {
			background: url("/common/img/terms/bg_ttl_time.png") top center no-repeat;
			background-size: 120px;
		}
		&--attention {
			background: url("/common/img/terms/bg_ttl_attention.png") top center no-repeat;
			background-size: 120px;
		}
	}
	
	.terms-ank {
		margin-bottom: 40px;
		
		&__inner {
			margin: 0 0 10px;
		}
		
		.btn {
			min-width: auto;
			width: 100%;
			margin: 0;
		}
	}
	
	.terms-block {
		margin: 0 0 50px;
	}
	
	.terms-flow {
		&__item {
			margin: 0 0 20px;
			
			.ttl03 {
				font-size: $xl;
			}
			
			.ttl06 {
				margin-top: 15px;
			}
			
			&__btn {
				text-align: center;
				margin: 15px 0 0;
				
				&__ico {
					margin: 0 10px 0 0;
					
					_:-ms-fullscreen, :root & {
						margin: -7px 10px 0 0;
					}
				}
				
				.btn {
					width: 100%;
					height: 70px;
					min-width: auto;
					
					_:-ms-fullscreen, :root & {
						padding: 10px 20px 5px;
					}
				}
			}
		}
	}
	
	.terms-list {
		list-style-type: none;
		counter-reset: number;
		
		&__item {
			position: relative;
			padding: 0 0 0 35px;
			margin: 0 0 10px;

			&::before {
				left: 0;
				position: absolute;
				counter-increment: number 1;
				content: "※" counter(number) " ";
				display: block;
				font-weight: bold;
			}
		}
	}
	
	#belongings {
		.box {
			margin: 0 0 20px;
		}
		
		.list {
			margin-bottom: 0;
			
			.text-sup {
				font-size: $xxs;
				vertical-align: top;
			}
		}
	}
	
	.terms-time {
		margin: 0 0 15px;

		&__tel {
			text-align: center;
			font-weight: bold;
			font-size: $l;
			margin: 0 0 10px;
		}
	}

	.terms-komelist {
		&__item {
			margin: 0 0 10px;
			
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.terms-attention {
		margin: 0 0 40px;
		
		&__text {
			margin: 0 0 25px;
		}
	}
}