@charset "utf-8";
/* ============================================================ */
/* service */
/* ============================================================ */
.service {
	.contents {
		&__inner {
			.ttl02 {
				margin: 0 0 20px;
			}
		}
	}
	
	.service-ank {
		margin-bottom: 30px;
		
		&__inner {
			margin: 0 0 10px;
		}
		
		.btn {
			min-width: auto;
			width: 100%;
			margin: 0;
		}
	}
	
	.service-block {
		margin: 20px 0 35px;
		
		&:last-of-type {
			margin: 0;
		}
		
		.ttl03 {
			margin: 0 0 40px;
		}
	}
	
	.tokucho-list {
		margin: 0;
		
		&__item {
			margin: 0 0 20px;
			background: url("/common/img/index/bg_about_inner.png") right bottom no-repeat $white;
			background-size: 153px;
			@include box-shadow;
			color: $textColor;
			
			&__img {
			}
			
			&__text {
				padding: 20px 15px;
			}
			
			.ttl06 {
				font-size: 1.7rem;
				text-align: center;	
				margin: 0 0 15px;
			}
		}
	}
	
	.stay-block {
		 &__item {
			 margin: 0 0 30px;
			 
			 &:last-of-type {
				 margin: 0;
			 }
			 
			 &__img {
				 margin: 0 0 20px;
			 }
			 
			 &__flow {
				 margin: 0 0 10px;
				 
				 &__inner {
					 border: 2px solid $baseColor;
					 padding: 10px 15px;
					 margin: 0 0 13px;
					 @include radius(5);
					 font-weight: bold;
					 text-align: center;
					 position: relative;
					 font-size: 1.7rem;
					 
					 _:-ms-fullscreen, :root & {
							padding: 15px 15px 8px;
					 }
					 
					 &:last-of-type {
						 &::after {
							 display: none;
						 }
					 }
					 
					 &::after {
							top: 100%;
							left: 50%;
							border: solid transparent;
							content: '';
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-top-color: $baseColor;
							border-width: 15px;
							margin-left: -15px;
					 }
					 
					 &__text {
						 &__small {
							 font-size: $xs;
						 }
					 }
				 }
			 }
		 }
	}

}