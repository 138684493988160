@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	/* --------------------------------------------------- */
	/* index-visual  */
	/* --------------------------------------------------- */
	.index-visual {
		background: url("/common/img/common/bg_pattern02.png") 0 0 repeat;
		background-size: 129px;
		height: calc(100vh - 100px);
		padding: 0 0 100px;
		position: relative;
		
		@media screen and (max-width: 1400px) {
			padding: 0 0 50px;
		}
		
		&__inner {
			position: relative;
			width: calc(100% - 200px);
			height: 100%;
			margin: 0 auto;

			@media screen and (max-width: 1400px) {
				width: calc(100% - 80px);
			}
			
			&__text {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
				
				&__item {
					width: 100%;
					height: 100%;
					margin: 0 auto;
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					text-align: center;

					&__img {
						width: 12.33%;
						margin: 0 auto 10px;
						min-height: 0.1px;
						
						@media screen and (max-width: 1280px) {
							width: 15%;
						}
						
						@media screen and (max-width: 980px) {
							width: 20%;
						}
						
					}

					&__ttl {
						font-weight: bold;
						font-size: 3.8rem;
						line-height: 1.3;
						color: $white;
						text-shadow: 0 0 15px rgba(#000, 0.3),0 0 15px rgba(#000, 0.3);
					}
				}
			}
			
			&__img {
				overflow: hidden;
				height: 100%;

				&__slider {
					height: 100%;
					position: relative;

					@media (orientation: portrait) {
						.pc-view ,
						.sp-view {
							display: none !important;
						}
						
						.ipad-view {
							display: block !important;
						}
					}
					
					.ipad-view {
						display: none;
					}
					
					&__img {
						position: absolute !important;
						top: 0 !important;
						left: 0 !important;
					}

					.slick-list ,
					.slick-track {
						height: 100%;
					}

					.object-fit-img {
						height: 100%;
						object-fit: cover;
						object-position: center center;
						font-family: 'object-fit: cover; object-position: center center;'
					}
				}
			}
		}
		
		.slick-dots {
			margin: -65px 0 0;
			z-index: 80;
		}
	}
	
	
	/* --------------------------------------------------- */
	/* index-news  */
	/* --------------------------------------------------- */
	.index-news {
		padding: 60px 0 65px;
		background: url("/common/img/index/bg_news.png") top center no-repeat $white;
		background-size: cover;
		position: relative;
		
		&::before {
			background: url("/common/img/index/img_foot.png") 0 0 no-repeat;
			background-size: contain;
			width: 534px;
			height: 325px;
			top: -176px;
			right: 80px;
			content: '';
			display: inline-block;
			position: absolute;
			
			@media screen and (max-width: 1410px) {
				display: none;
			}
		}
		
		&__inner {
			width: calc(100% - 200px);
			margin: 0 auto;
			
			@media screen and (max-width: 1590px) {
				width: calc(100% - 80px);
			}
			
			&__ttl {
				background: url("/common/img/index/bg_news_ttl.png") top center no-repeat;
			}
			
			&__list {
				display: flex;
				margin: 0 -20px 60px;
				
				@media screen and (max-width: 1460px) {
					flex-wrap: wrap;
					margin: 0 -20px 30px;
				}
				
				&__item {
					width: calc((100% - 161px) / 4 );
					margin: 0 20px;
					background: $white;
					@include box-shadow;
					
					@media screen and (max-width: 1460px) {
						width: calc((100% - 81px) / 2 );
						margin: 0 20px 30px;
					}
					
					&__link {
						display: block;
						text-decoration: none;
						padding: 40px 50px;
						
						@media screen and (max-width: 1700px) {
							padding: 25px 35px;
						}
						
						&__inner {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							margin: 0 0 20px;
														
							&__img {
								width: 120px;
								height: 100px;
								margin: 0 25px 0 0;
								display: flex;
								justify-content: center;
								align-items: center;
								overflow: hidden;
								
								&__inner {
									width: auto;
								}
							}
							
							&__text {
								width: calc(100% - 145px);
								font-size: 1.5rem;
								
								&__date {
									font-weight: normal;
									margin: 5px 0 0;
									display: block;
									color: $textColor;
								}
							}
						}
					}
				}
			}
			
			&__btn {
				text-align: center;
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-concept  */
	/* --------------------------------------------------- */
	.index-concept {
		background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
		padding: 90px 0 100px;
		position: relative;
		
		&::before {
			background: url("/common/img/index/bg_about_left.png") top left no-repeat;
			background-size: cover;
			width: 620px;
			height: 267px;
			top: 0;
			left: 0;
			content: '';
			display: inline-block;
			position: absolute;
			
			@media screen and (max-width: 1280px) {
				width: 380px;
				height: 164px;
			}
		}
		
		&::after {
			background: url("/common/img/index/bg_about_right.png") top right no-repeat;
			background-size: cover;
			width: 620px;
			height: 267px;
			top: 0;
			right: 0;
			content: '';
			display: inline-block;
			position: absolute;
			
			@media screen and (max-width: 1280px) {
				width: 380px;
				height: 164px;
			}
		}
		
		&__inner {
			width: calc(100% - 200px);
			margin: 0 auto;
			text-align: center;
			color: $white;
			
			@media screen and (max-width: 1400px) {
				width: calc(100% - 80px);
			}
			
			&__ttl {
				color: $beige;
				background: url("/common/img/index/bg_concept_ttl.png") top center no-repeat;
				
				@media screen and (max-width: 1160px) {
					margin: 0 0 20px;
				}
			}
			
			&__list {
				display: flex;
				flex-wrap: wrap;
				margin: 80px 0 0;
                justify-content: center;
				
				@media screen and (max-width: 1160px) {
					margin: 40px 0 0;
				}
				
				&__item {
					width: calc((100% - 76px) / 3);
					margin: 0 12.5px 60px;
					background: url("/common/img/index/bg_about_inner.png") right bottom no-repeat $white;
					background-size: 153px;
					@include box-shadow;
					color: $textColor;
					position: relative;
					
					&__ico {
						width: 25.64%;
						position: absolute;
						top: -8.18%;
						left: -4.55%;
						
						@media screen and (max-width: 1385px) {
							width: 30%;
						}
						
						@media screen and (max-width: 1050px) {
							width: 35%;
						}
					}
					
					&__img {
					}
					
					&__text {
						padding: 30px 25px;
						font-weight: bold;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						@media screen and (max-width: 1285px) {
							br {
								display: none;
							}
						}
						
						&__small {
							font-size: 1.5rem;
						}
					}
				}
			}
			
			&__btn {
				.btn {
					margin: 0 15px;
				}
			}
		}
	}
	
	/* --------------------------------------------------- */
	/* index-terms  */
	/* --------------------------------------------------- */
	.index-terms {
		background: url("/common/img/index/bg_terms.png") center 3px no-repeat;
		background-size: 470px;
		padding: 100px 0;
		
		@media screen and (max-width: 1410px) {
			background-size: 40%;
		}
		
		@media screen and (max-width: 1160px) {
			padding: 60px 0 40px;
		}

		&__inner {
			width: calc(100% - 200px);
			margin: 0 auto;
			text-align: center;
			position: relative;
			
			@media screen and (max-width: 1400px) {
				width: calc(100% - 80px);
			}
			
			&__ttl {
				background: url("/common/img/index/bg_terms_ttl.png") top center no-repeat;
				position: relative;
				z-index: 2;
			}
			
			&__text {
				position: relative;
				z-index: 2;
				font-weight: bold;
			}
			
			&__list {
				max-width: 1230px;
				margin: 60px auto 0;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				z-index: 2;
				
				@media screen and (max-width: 1400px) {
					margin: 60px 30px 0;
				}
				
				&__item {
					width: calc((100% - 91px) / 3);
					margin: 0 15px 30px;
					
					&__img {
						width: 73.68%;
						margin: 0 auto 20px;
					}
					
					&__text {
						background: rgba($white, 0.45);
						text-align: center;
						line-height: 1.4;
						position: relative;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-weight: bold;
						
						&::after,
						&::before {
							content: '';
							width: 15px;
							height: 15px;
							position: absolute;
							display: inline-block;
						}
						
						&::before {
							border-left: 2px solid $baseColor;
							border-top: 2px solid $baseColor;
							top: 0;
							left: 0;
						}
						
						&::after {
							border-right: 2px solid $baseColor;
							border-bottom: 2px solid $baseColor;
							bottom: 0;
							right: 0;
						}
						
						&__detail {
							padding: 30px;
							
							@media screen and (max-width: 1295px) {
								br {
									display: none;
								}
							}
							
							&::after, 
							&::before {
								content: '';
								width: 15px;
								height: 15px;
								position: absolute;
								display: inline-block;
							}
							
							&::before {
								border-left: 2px solid $baseColor;
								border-bottom: 2px solid $baseColor;
								bottom: 0;
								left: 0;
							}
							
							&::after {
								border-right: 2px solid $baseColor;
								border-top: 2px solid $baseColor;
								top: 0;
								right: 0;
							}
						}
					}
				}
			}
			
			&__bg {
				background: url("/common/img/common/bg_pattern02.png") 0 0 repeat;
				background-size: 100%;
				width: 100%;
				height: 66.18%;
				position: absolute;
				top: 26.73%;
				left: 0;
				z-index: 1;
			}
		}
	}
	
}