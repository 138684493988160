@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	padding: 70px 0 0;
	position: relative;
	z-index: 2;
	
	&::after {
		background: url("/common/img/common/bg_footer.png") bottom center no-repeat;
		background-size: cover;
		width: 100%;
		height: 200px;
		bottom: 100px;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;
		z-index: 1;
	}
	
	&__inner {
		margin: 0 20px 50px;
		position: relative;
		z-index: 3;
		
		&--column2 {
			.main {
				margin-bottom: 50px;
			}
		}
	}
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.text-bold {
	font-weight: bold;
}

.text-green {
	color: $baseColor;
	font-weight: bold;
}

.text-pink {
	color: $pink;
	font-weight: bold;
}

.text-center {
	text-align: center;
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("/common/img/error/bg_ttl_sp.jpg") center center no-repeat;
	background-size: cover;
	height: 150px;
	font-size: $xxxl;
	font-weight: bold;
	text-align: center;
	line-height: 1.2;
	color: $white;
	padding: 0 20px;
	text-shadow: 0 0 15px rgba(#000, 0.3),0 0 15px rgba(#000, 0.3);
		
	.news & {
		background: url("/common/img/news/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.price & {
		background: url("/common/img/price/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.terms & {
		background: url("/common/img/terms/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.service & {
		background: url("/common/img/service/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.about & {
		background: url("/common/img/about/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.contact & {
		background: url("/common/img/contact/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.privacy & {
		background: url("/common/img/privacy/bg_ttl_sp.jpg") center center no-repeat;
		background-size: cover;
	}
}

.ttl02 {
	font-size: $xxl;
	line-height: 1.3;
	margin: 0 0 25px;
	padding: 40px 0 0;
	text-align: center;
	color: $textColor;
	font-weight: bold;
	
	.about & {
		background: url("/common/img/about/bg_ttl_about.png") top center no-repeat;
		background-size: 120px;
	}
	
	.service & {
		background: url("/common/img/service/bg_ttl_service.png") top center no-repeat;
		background-size: 120px;
	}
	
	.price & {
		background: url("/common/img/price/bg_ttl_price.png") top center no-repeat;
		background-size: 120px;
	}
	
	.terms & {
		background: url("/common/img/terms/bg_ttl_flow.png") top center no-repeat;
		background-size: 120px;
	}
	
	.contact & {
		background: url("/common/img/contact/bg_ttl_tel.png") top center no-repeat;
		background-size: 120px;
	}
	
	.news & {
		background: url("/common/img/index/bg_news_ttl.png") top center no-repeat;
		background-size: 120px;
	}
	
	.template & {
		background: url("/common/img/common/bg_ttl_template.png") top center no-repeat;		
		background-size: 120px;
	}
}

.ttl03 {
	margin: 0 0 35px;
	padding: 40px 0 0;
	font-size: $xl;
	font-weight: bold;
	text-align: center;
	line-height: 1.2;
	position: relative;
	
	&::before {
		content: "\e900";
		font-family: 'icomoon';
		color: rgba(225,218,204, 0.7);
		position: absolute;
		top: 0;
		left: 50%;
		@include centering-elements(true, false);
		font-size: 2.6rem;
		font-weight: normal;
	}
	
	&::after {
		position: absolute;
		left: 50%;
		bottom: -15px;
		@include centering-elements(true, false);
		content: '';
		width: 50px;
		border-bottom: 2px solid $baseColor;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 15px;
	padding: 15px 0 0;
	border-top: 2px solid $baseColor;
	font-size: $l;
	font-weight: bold;
	line-height: 1.4;
	color: $baseColor;
	
	&::before {
		position: absolute;
		left: 0;
		top: 4px;
		content: '';
		width: 100%;
		border-bottom: 2px dotted rgba($baseColor, 0.6);
	}
}

.ttl05 {
	margin: 0 0 15px;
	padding: 0 0 0 40px;
	font-size: 1.7rem;
	font-weight: bold;
	line-height: 1.4;
	position: relative;
	
	&::before {
		content: "\e900";
		font-family: 'icomoon';
		color: rgba(225,218,204, 0.7);
		position: absolute;
		top: -5px;
		left: 0;
		font-size: $xxl;
		font-weight: normal;
	}
}

.ttl06 {
	margin: 0 0 15px;
	color: $baseColor;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.4;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 10px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
		
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 20px;
	background: $white;

	&--beige {
		background: rgba($beige, 0.3);
	}

	&--light-green {
		background: rgba($baseColor, 0.1);
	}
	
	&--border-green {
		border: 3px solid rgba($baseColor, 0.3);
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
		font-weight: bold;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid $borderColor;
	table-layout: fixed;
	font-size: 1.5rem;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 15px;
		background: #fff;
		border: 1px dotted #ccc;
		text-align: left;
		vertical-align: middle;
	}
	th {
		font-weight: bold;
	}
	.bg-green {
		background: $baseColor;
		color: $white;
	}
	.bg-beige {
		background: rgba($beige, 0.3);
	}
	.text-center {
		text-align: center;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
}

.table-wrap {
	width: 100%;
	padding: 0 0 5px;
	overflow-x: auto;
	
	.table {
		width: 150%;
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track{
		background: #f2f2f2;
	}
	&::-webkit-scrollbar-thumb {
		background: #c2c2c2;
	}	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	min-height: 55px;
	margin: 5px 0;
	padding: 5px 20px;
	color: $white;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(5);
	font-weight: bold;
	font-size: 1.7rem;
	
	_:-ms-fullscreen, :root & {
		padding: 17px 20px 5px;
	}
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&--border {
		background: $white;
		border: 2px solid $baseColor;
		color: $baseColor;
	}
	
	&--border-white {
		border: 2px solid $white;
		color: $white;
		background: none;
	}
	
	&--brown {
		background: $textColor;
		color: $beige;
	}
	
	&--green {
		background: $baseColor;
		color: $white;
		border: 2px solid $baseColor;
	}
	
	&--gray {
		background: #d9d9d9;
    color: $white;
	}
	
	&--lg {
		width: 100%;
		min-height: 55px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 45px;
		padding: 0 10px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 8px 12px;
			border: 1px solid $borderColor;
			background: $white;
			color: $textColor;
			text-decoration: none;
			@include radius(5);
			font-weight: bold;
			font-size: $s;
			
			&.current {
				color: $white;
				border: 1px solid $baseColor;
				background: $baseColor;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	border: 2px solid $borderColor;
	background: rgba($beige, 0.2);
	color: $textColor;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 15px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
		font-size: $l;
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	position: absolute;
	top: 13px;
	right: 10px;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	border: 1px solid $white;
	font-size: $xxs;
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
	color: #dd0000;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 5px;
	background: $white;
	border: 1px solid #d2d2d2;
}

input::-webkit-input-placeholder {
	color: #C3C3C3;
}

textarea::-webkit-input-placeholder {
	color: #C3C3C3;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	margin: 0 0 5px;
	display: block;
	
	&:last-of-type {
		margin: 0;
	}
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: inline-block;
	text-align: center;
	padding: 1px 10px;
	min-width: 45px;
	box-sizing: border-box;
	color: $white;
	font-size: $xs;
	@include radius(20);
	font-weight: normal;
	
	&--yellow {
		background: $yellow;
	}
	
	&--pink {
		background: $pink;
	}
	
	&--green {
		background: $baseColor;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 50px;
	border-top: 1px dotted $borderColor;
	
	&__item {
		border-bottom: 1px dotted $borderColor;
		
		div[class="news-list__item__link"] {
			.news-list {
				&__item {
					&__link {
						&__detail {
							&__ttl {
								&__link {
									text-decoration: none;
									color: $textColor;
								}
							}
						}
					}
				}
			}
		}
		
		&__link {
			display: flex;
			justify-content: space-between;
			padding: 20px 0;
			text-decoration: none;
			font-weight: normal;
			color: $textColor;
						
			&__img {
				width: 80px;
				height: 65px;
				margin: 0 25px 0 0;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				
				&__inner {
					width: auto;
				}
			}
			
			&__detail {
				display: flex;
				flex-direction: column;
				width: calc(100% - 100px);
				
				&__date {
					font-size: $s;
				}
				
				&__label {
					max-width: 100px;
					margin: 5px 0;
				}
				
				&__ttl {
					color: $baseColor;
					font-weight: bold;

					&__link {
						text-decoration: underline;
						
						&__ico {
							margin: 0 0 0 5px;
						}
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
		
		.ttl03 {
			margin: 15px 0 0;
			width: 100%;
		}
	}
	&__block {
		padding: 0 0 50px;
		margin: 0 0 40px;
		border-bottom: 1px dotted $borderColor;
		
		img {
			width: 100% !important;
			height: 100% !important;
		}
	}

}


/* --------------------------------------------------- */
/* noarticle-block */
/* --------------------------------------------------- */
.noarticle-block {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
		
		.ttl03 {
			margin: 10px 0 0;
			width: 100%;
		}
	}
	&__block {
		padding: 0 0 50px;
		margin: 0 0 40px;
		border-bottom: 1px dotted $borderColor;
	}
}


/* --------------------------------------------------- */
/* block-contact */
/* --------------------------------------------------- */
.block-contact {
	margin: 30px 20px 0;
	padding: 35px 20px;
	background: $white;
	@include box-shadow;
	position: relative;
	z-index: 3;
	
	&::before {
		background: url("/common/img/common/bg_contact_top.png") top right no-repeat;
		background-size: cover;
		width: 175px;
		height: 78px;
		top: 0;
		right: 0;
		content: '';
		display: inline-block;
		position: absolute;
	}

	&::after {
		background: url("/common/img/common/bg_contact_bottom.png") bottom left no-repeat;
		background-size: cover;
		width: 175px;
		height: 78px;
		bottom: 0;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;
	}
	
	&__ttl {
		background: url("/common/img/common/bg_contact_ttl.png") top center no-repeat !important;
		background-size: 120px !important;
		margin: 0 0 10px;
	}
	
	&__inner {
		margin: 15px 0 0;
		font-weight: bold;
		
		&__tel {
			margin: 0 0 20px;
			text-align: center;
			
			&__number {
				font-size: 2.6rem;
				color: $baseColor !important;
				line-height: 1.4;
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 65px;
				padding: 0 15px;
				margin: 0 0 10px;
				text-align: center;
				text-decoration: none;
				@include radius(5);
				font-weight: bold;
				border: 2px solid $baseColor;
				
				_:-ms-fullscreen, :root & {
					padding: 10px 15px 0;
				}
				
				&__small {
					font-size: 1.5rem;
				}
			}
			
			&__text {
				&__green {
					color: $baseColor !important;
				}
			}
		}
		
		&__btn {
			.btn {
				width: 100%;
				height: 100px;
				padding: 0 10px;
				
				_:-ms-fullscreen, :root & {
					padding: 10px 10px 0;
				}
				
				.icon-mail {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* block-bnr  */
/* --------------------------------------------------- */
.block-bnr {
	margin: 30px 20px 0;
	position: relative;
	z-index: 4;

	&__link {
		margin: 0 0 15px;
		@include box-shadow;
		display: block;
	}
}	



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.side {
	&__snav {
		&__ttl {
			background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
			color: $beige;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 15px;
			font-size: $l;
			text-align: center;
			font-weight: bold;
			
			&__text {
				position: relative;
				padding: 30px 0 0;
				
				&::before {
					content: "\e900";
					font-family: 'icomoon';
					color: rgba($beige, 0.4);
					position: absolute;
					top: 0;
					left: 50%;
					@include centering-elements(true, false);
					font-size: $xxl;
					font-weight: normal;
				}	
			}
		}
		
		&__inner {
			border-bottom: 1px solid $borderColor;
			
			&__item {
				padding: 15px;
				border: 1px solid $borderColor;
				background: $white;
				border-top: none;
				border-bottom: 1px dotted $borderColor;
				width: 100%;
				display: flex;
				align-items: center;
				text-decoration: none;
				font-size: 1.5rem;
				color: $textColor;
				font-weight: bold;

				&:last-of-type {
					border-bottom: none;
				}

				&__text {
				}
				
				.icon-angle-right {
					margin: 0 10px 0 0;
					color: $baseColor;
				}
			}
		}
	}
}
