@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	height: 100px;
	z-index: 100;
	background: url("/common/img/common/bg_pattern02.png") 0 0 repeat;
	background-size: 129px;
	transition: .3s;
	
	.header-inner {
		display: flex;
		justify-content: space-between;
		transition: .3s;
		
		&__logo {
			background: $baseColor;
			width: 250px;
			padding: 30px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			transition: transform .3s;
			position: relative;
			z-index: 50;
						
			@media screen and (max-width: 1400px) {
				width: 200px;
				padding: 20px;
			}
			
			@media screen and (max-width: 1160px) {
				width: 180px !important;
				padding: 20px 15px !important;
			}
			
			@media screen and (max-width: 880px) {
				width: 150px !important;
			}
			
			&__link {
				width: 100%;
				height: 100%;
				transition: transform .3s;
				
				&__yoko {
					display: none;
					transition: transform .3s;
				}
			}
			
			&__tel {
				color: $beige;
				font-weight: bold;
				font-size: $xxl;
				transition: transform .3s;
				margin: 5px 0 0;
				
				@media screen and (max-width: 1400px) {
					font-size: 1.7rem;
				}
				
				@media screen and (max-width: 1160px) {
					font-size: 1.5rem !important;
				}
				
				&__text {
					font-size: 1.5rem;
					
					@media screen and (max-width: 1400px) {
						font-size: $xs;
					}
				}
			}
		}
		
		&__gnav {
			height: 100px;
			
			&__inner {
				display: flex;
				align-items: center;
				height: 100px;
				
				&__item {
					color: $textColor;
					font-weight: bold;
					display: block;
					margin: 0 30px 0 0;
					text-decoration: none;
					transition: 0.3s all;

					@media screen and (max-width: 1400px) {
						margin: 0 15px 0 0;
						font-size: $m;
					}

					@media screen and (max-width: 1160px) {
						margin: 0 10px 0 0;
						font-size: $s;
					}

					&:last-of-type {
						margin: 0;
					}

					&__text {
						position: relative;
						z-index: 2;
					}

					&.current ,
					&:hover {
						color: $baseColor;
						position: relative;

						&::after {
							content: "\e900";
							font-family: 'icomoon';
							position: absolute;
							top: 50%;
							left: 50%;
							@include centering-elements(true true);
							color: rgba(225,218,204, 0.8);
							font-size: 4.0rem;
							z-index: 1;
							font-weight: normal;
						}
					}

					&--contact {
						background: $textColor;
						color: $white;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						min-width: 270px;
						height: 100px;
						padding: 0 15px;
						transition: 0.3s all;
						overflow: hidden;
						transform: perspective(1px) translateZ(0);
						transition-property: color;
						transition-duration: 0.3s;

						@media screen and (max-width: 1400px) {
							min-width: 200px;
							flex-direction: column;
						}

						@media screen and (max-width: 1160px) {
							min-width: 100px;
							padding: 0 10px;
						}

						&__ico {
							color: $beige;
							margin: 0 10px 0 0;

							@media screen and (max-width: 1400px) {
								font-size: 3.0rem;
								line-height: 1.0;
								margin: 0 0 5px;
								display: block;
							}
						}

						&__text {
							@media screen and (max-width: 1400px) {
								display: block;
							}

							@media screen and (max-width: 1160px) {
								font-size: $xs;
							}
						}

						&::before {
							content: "";
							position: absolute;
							z-index: -1;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							transform: scaleX(0);
							transform-origin: 0 50%;
							transition-property: transform;
							transition-duration: 0.3s;
							transition-timing-function: ease-out;
							background: $yellow;
						}

						&:hover::before, 
						&:focus::before, 
						&:active::before {
							transform: scaleX(1);
							color: $textColor !important;
						}

						&:hover {
							color: $textColor !important;

							.header-inner {
								&__gnav {
									&__item {
										&--contact {
											&__ico {
												color: $textColor;
											}
										}
									}
								}
							}

							&::after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.cb-header {
	height: 100px;
	background: $white;
	transition: .3s;
	@include box-shadow;
	transform: translateY(0);
	overflow: visible!important;
	position: fixed;
	top: 0;
	left: 0;
	
	.header-inner {
		transition: .3s;
		
		&__logo {
			height: 100px;
			transition: transform .3s;
			padding: 15px 20px;
			position: relative;
			z-index: 100;
			
			&__link {
				width: 100% !important;
				height: 100% !important;
				transition: transform .3s;
				
				&__yoko {
					display: block;
					width: auto;
					height: 100%;
					transition: transform .3s;
				}
				
				&__img {
					display: none;
					transition: transform .3s;
				}
			}
			
			&__tel {
				display: none;
				transition: transform .3s;
			}
		}
	}
}

.contact {
	.header-inner {
		&__gnav {
			&__inner {
				&__item {
					&--contact {
						background: $yellow;
						color: $textColor !important;
						
						&__ico {
							color: $textColor;
						}
						
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}	
}
