@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	position: relative;
	z-index: 2;
		
	&::after {
		background: url("/common/img/common/bg_footer.png") bottom center no-repeat;
		background-size: 100%;
		width: 100%;
		height: 600px;
		bottom: 200px;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;
		z-index: 1;
	}
	
	&__inner {
		max-width: 1200px;
		margin: 0 auto 150px;
		position: relative;
		z-index: 3;
		
		@media screen and (max-width: 1275px) {
			margin: 0 30px 70px;
		}
		
		&--column2 {
			display: flex;
			justify-content: space-between;
			
			.main {
				width: calc(100% - 240px);
				margin: 0 70px 0 0;
				
				@media screen and (min-width:  767.5px) and (max-width:1024px) { 
					width: calc(100% - 24vw);
					margin: 0 40px 0 0;
				}
			}
			.side {
				width: 240px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 24vw; }
			}
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.text-bold {
	font-weight: bold;
}

.text-green {
	color: $baseColor;
	font-weight: bold;
}

.text-pink {
	color: $pink;
	font-weight: bold;
}

.text-center {
	text-align: center;
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("/common/img/error/bg_ttl.jpg") center center no-repeat;
	background-size: cover;
	height: 300px;
	font-size: 4.0rem;
	font-weight: bold;
	text-align: center;
	line-height: 1.2;
	color: $white;
	text-shadow: 0 0 15px rgba(#000, 0.3),0 0 15px rgba(#000, 0.3);
	
	@media screen and (min-width: 767.5px) and (max-width: 1024px) { 
		height: 23vh;
		font-size: 3.4rem;
	}
	
	.news & {
		background: url("/common/img/news/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.price & {
		background: url("/common/img/price/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.terms & {
		background: url("/common/img/terms/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.service & {
		background: url("/common/img/service/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.about & {
		background: url("/common/img/about/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.contact & {
		background: url("/common/img/contact/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
	
	.privacy & {
		background: url("/common/img/privacy/bg_ttl.jpg") center center no-repeat;
		background-size: cover;
	}
}

.ttl02 {
	font-size: 3.2rem;
	line-height: 1.4;
	margin: 0 0 35px;
	padding: 60px 0 0;
	text-align: center;
	color: $textColor;
	font-weight: bold;
	
	.about & {
		background: url("/common/img/about/bg_ttl_about.png") top center no-repeat;
	}
	
	.service & {
		background: url("/common/img/service/bg_ttl_service.png") top center no-repeat;
	}
	
	.price & {
		background: url("/common/img/price/bg_ttl_price.png") top center no-repeat;
	}
	
	.terms & {
		background: url("/common/img/terms/bg_ttl_flow.png") top center no-repeat;
	}
	
	.contact & {
		background: url("/common/img/contact/bg_ttl_tel.png") top center no-repeat;
	}
	
	.news & {
		background: url("/common/img/index/bg_news_ttl.png") top center no-repeat;
	}
	
	.template & {
		background: url("/common/img/common/bg_ttl_template.png") top center no-repeat;		
	}
}

.ttl03 {
	margin: 0 0 50px;
	padding: 50px 0 0;
	font-size: 2.8rem;
	font-weight: bold;
	text-align: center;
	line-height: 1.4;
	position: relative;
	
	&::before {
		content: "\e900";
		font-family: 'icomoon';
		color: rgba(225,218,204, 0.7);
		position: absolute;
		top: 0;
		left: 50%;
		@include centering-elements(true, false);
		font-size: 3.4rem;
		font-weight: normal;
	}
	
	&::after {
		position: absolute;
		left: 50%;
		bottom: -20px;
		@include centering-elements(true, false);
		content: '';
		width: 50px;
		border-bottom: 2px solid $baseColor;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 30px;
	padding: 20px 0 0;
	border-top: 2px solid $baseColor;
	font-size: $xxl;
	font-weight: bold;
	line-height: 1.4;
	color: $baseColor;
	
	&::before {
		position: absolute;
		left: 0;
		top: 4px;
		content: '';
		width: 100%;
		border-bottom: 2px dotted rgba($baseColor, 0.6);
	}
}

.ttl05 {
	margin: 0 0 20px;
	padding: 0 0 0 45px;
	font-size: $xl;
	font-weight: bold;
	line-height: 1.4;
	position: relative;
	
	&::before {
		content: "\e900";
		font-family: 'icomoon';
		color: rgba(225,218,204, 0.7);
		position: absolute;
		top: 50%;
		left: 0;
		@include centering-elements(false, true);
		font-size: 2.6rem;
		font-weight: normal;
	}
}

.ttl06 {
	margin: 0 0 15px;
	color: $baseColor;
	font-size: $l;
	font-weight: bold;
	line-height: 1.4;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 10px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
		
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 45px;
	box-sizing: border-box;
	background: $white;

	&--beige {
		background: rgba($beige, 0.3);
	}

	&--light-green {
		background: rgba($baseColor, 0.1);
	}
	
	&--border-green {
		border: 3px solid rgba($baseColor, 0.3);
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
		font-weight: bold;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 20px 15px;
		background: $white;
		border: 1px dotted #ccc;
		font-size: $l;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	th {
		font-weight: bold;
	}
	.bg-green {
		background: $baseColor;
		color: $white;
	}
	.bg-beige {
		background: rgba($beige, 0.3);
	}
	.text-center {
		text-align: center;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 300px;
	height: 70px;
	padding: 0 30px;
	color: $white;
	font-size: $xl;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	overflow: hidden;
	background: none;
	transform: perspective(1px) translateZ(0);
	transition-property: color;
	transition-duration: 0.3s;
	@include radius(5);
	font-weight: bold;
	
	_:-ms-fullscreen, :root & {
		padding: 10px 30px 0;
	}
	
	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}
	
	&:hover:before, 
	&:focus:before, 
	&:active:before {
		transform: scaleX(1);
	}

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }

	&--border {
		background: $white;
		border: 2px solid $baseColor;
		color: $baseColor;
		
		&::before {
			background: $baseColor;
		}
		
		&:hover, 
		&:focus, 
		&:active {
			color: $white;
		}
	}
	
	&--border-white {
		border: 2px solid $white;
		color: $white;
		
		&::before {
			background: $white;
		}
		
		&:hover, 
		&:focus, 
		&:active {
			color: $baseColor;
		}
	}
	
	&--brown {
		background: $textColor;
		color: $beige;
		
		&::before {
			background: $yellow;
		}
		
		&:hover, 
		&:focus, 
		&:active {
			color: $textColor;
		}
	}

	&--green {
		background: $baseColor;
		color: $white;
		border: 2px solid $baseColor;
		
		&::before {
			background: $white;
		}
		
		&:hover, 
		&:focus, 
		&:active {
			color: $baseColor;
		}
	}
	
	&--gray {
		background: #d9d9d9;
    color: $white;
		
		&::before {
			background: $white;
			border: 2px solid #d9d9d9;
		}
		
		&:hover, 
		&:focus, 
		&:active {
			color: #bcbcbc;
		}
	}
	
	&--lg {
		min-width: 350px;
		height: 80px;
	}
	&--sm {
		min-width: inherit;
		height: 50px;
		font-size: $m;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
	@include transition;
	
	&:hover {
		opacity: 1;
	}
}

input[type="button"].btn--border,
input[type="submit"].btn--border {
	&:hover {
		background: $baseColor;
		color: $white;
		border: 2px solid $baseColor;
	}
}

input[type="button"].btn--border-white,
input[type="submit"].btn--border-white {
	&:hover {
		background: $white;
		color: $baseColor;
		border: 2px solid $white;
	}
}

input[type="button"].btn--brown,
input[type="submit"].btn--brown {
	&:hover {
		background: $yellow;
		color: $textColor;
	}
}

input[type="button"].btn--green,
input[type="submit"].btn--green {
	&:hover {
		background: $white;
		color: $baseColor;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			border: 1px solid $borderColor;
			background: $white;
			color: $textColor;
			text-decoration: none;
			position: relative;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			transform: perspective(1px) translateZ(0);
			transition-property: color;
			transition-duration: 0.3s;
			@include radius(5);
			
			&::before {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transform: scaleX(0);
				transform-origin: 0 50%;
				transition-property: transform;
				transition-duration: 0.3s;
				transition-timing-function: ease-out;
				background: $baseColor;
				border: 1px solid $baseColor;
			}

			&:hover:before, 
			&:focus:before, 
			&:active:before {
				transform: scaleX(1);
			}
			
			&:hover, 
			&:focus, 
			&:active {
				color: $white;
				border: 1px solid $baseColor;
			}

			&.current {
				color: $white;
				border: 1px solid $baseColor;
				background: $baseColor;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.sp-accordion ,
.accordion {
	position: relative;
	padding: 15px 30px 15px 15px;
	border: 2px solid $borderColor;
	background: rgba($beige, 0.2);
	color: $textColor;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	font-weight: bold;
	
	&__icon {
		right: 15px;
		color: $baseColor;
		@include centering-elements(false, true);
		font-size: $xl;
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	padding: 2px 10px;
	margin: 0 0 0 15px;
	background: $baseColor;
	color: $white;
	border: 1px solid $white;
	font-size: $xxs;
	
	_:-ms-fullscreen, :root & {
		padding: 5px 10px 2px;
	}
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
	color: #dd0000;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: $m;
	@include radius(0);
	
	_:-ms-fullscreen, :root & {
		padding: 5px 10px 0;
	}
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid $borderColor;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid $borderColor;
}

input::-webkit-input-placeholder {
	color: #C3C3C3;
}

textarea::-webkit-input-placeholder {
	color: #C3C3C3;
}

input::-moz-placeholder {
	color: #C3C3C3;
}

textarea::-moz-placeholder {
	color: #C3C3C3;
}

input:-ms-input-placeholder {
  color: #C3C3C3;
}

textarea:-ms-input-placeholder {
  color: #C3C3C3;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	display: inline-block;
	margin: 0 10px 0 0;
		
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: inline-block;
	text-align: center;
	padding: 3px 10px;
	min-width: 95px;
	box-sizing: border-box;
	color: $white;
	font-size: $xs;
	@include radius(20);
	font-weight: normal;
	
	&--yellow {
		background: $yellow;
	}
	
	&--pink {
		background: $pink;
	}
	
	&--green {
		background: $baseColor;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 50px;
	border-top: 1px dotted $borderColor;
	
	&__item {
		border-bottom: 1px dotted $borderColor;
		
		div[class="news-list__item__link"] {
			.news-list {
				&__item {
					&__link {
						&__detail {
							&__ttl {
								&__link {
									text-decoration: none;
									color: $textColor;
								}
							}
						}
					}
				}
				
				&:hover {
					.news-list {
						&__item {
							&__link {
								&__detail {
									&__ttl {
										&__link {
											text-decoration: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		&__link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 25px;
			text-decoration: none;
			font-weight: normal;
			color: $textColor;
			
			&:hover {
				.news-list {
					&__item {
						&__link {
							&__detail {
								&__ttl {
									&__link {
										text-decoration: none;
									}
								}
							}
						}
					}
				}
			}
			
			&__img {
				width: 120px;
				height: 100px;
				margin: 0 25px 0 0;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				
				&__inner {
					width: auto;
				}
			}
			
			&__detail {
				display: flex;
				flex-direction: column;
				width: calc(100% - 145px);
				
				&__date {
					font-size: $m;
				}
				
				&__label {
					max-width: 95px;
					margin: 5px 0;
				}
				
				&__ttl {
					color: $baseColor;
					font-weight: bold;

					&__link {
						text-decoration: underline;
						
						&__ico {
							margin: 0 0 0 5px;
						}
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 60px;
		
		.ttl03 {
			margin: 10px 0 0;
			width: 100%;
		}
	}
	&__block {
		padding: 0 0 80px;
		margin: 0 0 60px;
		border-bottom: 1px dotted $borderColor;
		
		img {
			width: 100% !important;
			height: 100% !important;
		}
	}
}


/* --------------------------------------------------- */
/* noarticle-block */
/* --------------------------------------------------- */
.noarticle-block {
	text-align: center;
	margin: 60px 0 0;
	
	&__ico {
		font-size: 4.0rem;
		margin: 0 0 10px;
		color: #c6baa3;
	}
	
	&__txt {
		font-weight: bold;
	}
}


/* --------------------------------------------------- */
/* block-contact */
/* --------------------------------------------------- */
.block-contact {
	max-width: 1200px;
	margin: 95px auto 0;
	padding: 60px;
	background: $white;
	@include box-shadow;
	position: relative;
	text-align: center;
	z-index: 3;
	
	@media screen and (max-width: 1270px) {
		margin: 95px 30px 0;
		padding: 40px;
	}
	
	@media screen and (max-width: 1160px) {
		margin: 45px 30px 0 !important;
	}
	
	&::before {
		background: url("/common/img/common/bg_contact_top.png") top right no-repeat;
		background-size: cover;
		width: 451px;
		height: 200px;
		top: 0;
		right: 0;
		content: '';
		display: inline-block;
		position: absolute;

		@media screen and (max-width: 1280px) {
			width: 280px;
			height: 124px;
		}
	}

	&::after {
		background: url("/common/img/common/bg_contact_bottom.png") bottom left no-repeat;
		background-size: cover;
		width: 451px;
		height: 200px;
		bottom: 0;
		left: 0;
		content: '';
		display: inline-block;
		position: absolute;

		@media screen and (max-width: 1280px) {
			width: 280px;
			height: 124px;
		}
	}
	
	&__ttl {
		background: url("/common/img/common/bg_contact_ttl.png") top center no-repeat !important;
		margin: 0 0 15px;
	}
	
	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 30px 0 0;
		font-weight: bold;
		
		&__tel {
			margin: 0 30px 0 0;
			
			@media screen and (max-width: 1090px) {
				margin: 0 15px 0 0;
			}
			
			&__number {
				font-size: 4.0rem;
				color: $baseColor !important;
				line-height: 1.4;
				
				&__small {
					font-size: $xxl;
				}
			}
			
			&__text {
				&__green {
					color: $baseColor !important;
				}
			}
		}
		
		&__btn {
			margin: 0 0 0 30px;
			
			@media screen and (max-width: 1090px) {
				margin: 0 0 0 15px;
			}
			
			.btn {
				height: 85px;
								
				.icon-mail {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* block-bnr  */
/* --------------------------------------------------- */
.block-bnr {
	max-width: 1260px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 4;

	@media screen and (max-width: 1260px) {
		margin: 0 15px;
	}

	&__link {
		width: calc((100% - 121px) / 2);
		margin: 0 30px;
		@include box-shadow;
		display: block;

		@media screen and (max-width: 1250px) {
			width: calc((100% - 61px) / 2);
			margin: 0 15px;
		}
	}
}
	



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.side {
	&__snav {
		&__ttl {
			background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
			color: $beige;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 150px;
			font-size: $xl;
			text-align: center;
			font-weight: bold;
			
			&__text {
				position: relative;
				padding: 35px 0 0;
				
				&::before {
					content: "\e900";
					font-family: 'icomoon';
					color: rgba($beige, 0.4);
					position: absolute;
					top: 0;
					left: 50%;
					@include centering-elements(true, false);
					font-size: 2.6rem;
					font-weight: normal;
				}	
			}
		}
		
		&__inner {
			border-bottom: 1px solid $borderColor;
			
			&__item {
				padding: 20px;
				border: 1px solid $borderColor;
				background: $white;
				border-top: none;
				border-bottom: 1px dotted $borderColor;
				width: 100%;
				display: flex;
				align-items: center;
				text-decoration: none;
				font-size: 1.7rem;
				color: $textColor;
				overflow: hidden;
				transform: perspective(1px) translateZ(0);
				transition-property: color;
				transition-duration: 0.3s;

				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					transform: scaleX(0);
					transform-origin: 0 50%;
					transition-property: transform;
					transition-duration: 0.3s;
					transition-timing-function: ease-out;
					background: rgba($baseColor, 0.05);
				}

				&:hover:before, 
				&:focus:before, 
				&:active:before {
					transform: scaleX(1);
				}

				&:hover, 
				&:focus, 
				&:active {
				}

				&:last-of-type {
					border-bottom: none;
				}

				&__text {
				}
				
				.icon-angle-right {
					margin: 0 10px 0 0;
					color: $baseColor;
				}
			}
		}
	}
}
