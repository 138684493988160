@charset "utf-8";
/* ============================================================ */
/* error */
/* ============================================================ */
.error {
	.error-text {
		margin: 0 0 20px;
		text-align: left;
	}
	
	.btn{
		width: 100%;
	}
}