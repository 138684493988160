@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	background: url("/common/img/common/bg_pattern02.png") 0 0 repeat;
	background-size: 129px;
	margin: 0 0 50px;
	width: 100%;
	position: fixed;
	z-index: 700;
	transition: .3s;

	.header-inner {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 70px;
		
		&__logo {
			background: $baseColor;
			width: 110px;
			height: 100px;
			
			&__link {
				width: 100%;
				height: 100%;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		
		&__menu {			
			display: flex;
			position: absolute;
			right: 0;
			top: 0;
			
			&__tel ,
			&__btn {
				width: 70px;
				height: 70px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				font-size: $xs;
				font-weight: bold;
			}
			
			&__tel {
				border-left: 1px solid rgba(85,70,40, 0.15);
				text-decoration: none;
				
				.icon-phone {
					font-size: $xxxl;
					line-height: 1.0;
				}
			}
			
			&__btn {
				background: $textColor;
				color: $white;
				
				.icon-menu {
					font-size: 2.6rem;
					line-height: 1.0;
					margin: 0 0 -2px;
				}
			}
		}
		
		&__gnav {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			padding: 0 0 20px;
			background: $baseColor;
			opacity: 0;
			overflow-y: auto;
			display: none;
			overflow: hidden;
			animation: menufade 0.5s ease;
						
			&.open {
				z-index: 800;
				opacity: 1;
				display: block;
				animation: menufade 0.5s ease;
			}
			
			&__btn {
				&--top {
					color: $white;
					width: 70px;
					height: 70px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: $xxxl;
					margin: 0 0 0 auto;
				}
				
				&--bottom {
					margin: 20px auto 0;
					text-align: center;
					color: $white;
					cursor: pointer;
					width: 55px;
					height: 60px;
					text-align: center;

					.icon-close {
						font-size: 3.0rem;
						display: block;
						line-height: 1.2;
					}
				}
			}
			
			&__inner {
				&__item {
					display: flex;
					align-items: center;
					padding: 15px;
					border-bottom: 1px solid rgba($white, 0.2);
					color: $white;
					font-size: $m;
					text-decoration: none;
					font-weight: bold;
					
					_:-ms-fullscreen, :root & {
						padding: 17px 15px 12px;
					}

					&:first-child {
						border-top: 1px solid rgba($white, 0.2);
					}
					.icon-angle-right {
						margin: 0 10px 0 0;
						
						_:-ms-fullscreen, :root & {
							margin: -8px 10px 0 0;
						}
					}
				}
			}
		}
	}
}

.is-animation {
	background: $white;
	transition: .3s;
	@include box-shadow;
}


