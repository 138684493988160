@charset "utf-8";
/* ============================================================ */
/* price */
/* ============================================================ */
.price {
	.ttl02 {
		&--option {
			background: url("/common/img/price/bg_ttl_option.png") top center no-repeat;
		}
	}
	.price-block {
		margin: 0 0 80px;
	}
	
	.price-table {
		margin: 0 0 40px;
		text-align: center;
		font-weight: bold;
		
		&__text {
			font-size: 1.5rem;
		}
	}
	
	.price-list {
		&__item {
			margin: 0 0 10px;
		}
	}
	
	.price-option {
		display: flex;
        justify-content: center;
		margin: 0 -15px;
		
		&__item {
			margin-left: 15px;
			margin-right: 15px;
			width: calc((100% - 61px) / 2 );
			
			@media screen and (max-width: 1025px) {
				padding: 35px;
                width: 70%;
			}
			
			&__img {
				margin: 0 0 30px;
			}
			
			.list {
				font-weight: bold;
				color: $baseColor;
				margin: 0 0 20px;
			}
		}
	}
}