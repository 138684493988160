@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $baseColor;
	color: $white;
	padding: 150px 0 0;
	margin: -100px 0 0;
	
	.footer-inner {
		&__logo {
			text-align: center;
			margin: 0 0 30px;
			
			&__link {
				width: 65%;
				display: block;
				margin: 0 auto;
			}
			
			&__address {
				margin: 15px 0 10px;
				
				a[href^="tel:"] {
					color: $white;
					font-weight: normal;
				}
			}
			
			&__sns {
				display: flex;
				justify-content: center;
				
				&__btn {
					margin: 0 5px 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 3.0rem;
					line-height: 1.0;
					
					&--twitter {
						padding: 7.5px;
					}	
					
					&__link {
						text-decoration: none;
						color: $white;
					}
				}
			}
		}
		
		&__fnav {
			border-top: 1px solid rgba($white, 0.3);
			
			&__item {
				&__link {
					display: block;
					color: $white;
					text-decoration: none;
					font-size: $m;
					font-weight: bold;
					@include transition;
					border-bottom: 1px solid rgba($white, 0.3);
					padding: 15px 20px;
					
					_:-ms-fullscreen, :root & {
						padding: 17px 20px 12px;
					}
					
					&__ico {
						margin: 0 10px 0 0;
					}
				}
			}
		}
	}
		
	.copyright {
		text-align: center;
		color: rgba($white, 0.7);
		padding: 30px 0 60px;
	}

}