@charset "utf-8";
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	.ttl02 {
		&--form {
			background: url("/common/img/contact/bg_ttl_form.png") top center no-repeat;
			background-size: 120px;
		}
	}
	
	.contact-block {
		margin: 0 0 40px;
		
		&:last-of-type {
			margin: 0;
		}
	}
	
	.contact-text {
		margin: 0 0 25px;
	}
	
	.contact-tel {
		&__box {
			text-align: center;
			padding: 15px;
			font-weight: bold;
			
			&__text {
				margin: 0;
				color: $baseColor;
				font-size: 1.5rem;
				
				&__link {
					color: $baseColor;
					font-size: 2.6rem;
					text-decoration: none;
				}
			}
		}
	}
	
	.contact-form {
		.list {
			margin: 0 0 20px;
		}
		
		&__table {
			margin: 20px 0;
			
			&__th {
				position: relative;
				
				&__text {
					font-size: $xs;
					font-weight: normal;
					display: block;
					margin: 5px 0 0;
				}
			}
			
			&__td {
				position: relative;
				
				&__input {
					width: 100%;
					
					&--wide {
						width: 100%;
					}
				}
				
				&__dog {
					width: 100%;
				}
				
				&__select ,
				&__select02 {
					width: 100%;
				}
				
				&__text {
					margin: 0 0 5px;
				}
			}
			
			.bg-beige {
				font-weight: bold;
			}
		}
		
		.table-dog {
			&__item {
				margin: 0 0 10px;
				
				&:last-of-type {
					margin: 0;
				}
				
				&__text {
					font-weight: bold;
					display: block;
					margin: 0 0 5px;
				}
			}
		}
	}
	
	.confirm-btn {
		.btn {
			margin: 0 0 10px;
		}
	}	
	
	.ui-datepicker {
		font-size: $m;
		line-height: 1.8;
	}
}

#contact_confirm {
	.contact-form {
		.text-center {
			text-align: left;
		}
	}
}

#contact_complete {
	.box {
		margin: 0 0 20px;
	}
	
	.list {
		margin-bottom: 0;
	}
	
	.btn {
		width: 100%;
	}
}
