@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $baseColor;
	color: $white;
	padding: 300px 0 65px;
	margin: -220px 0 0;
	
	@media screen and (max-width: 1260px) {
		padding: 300px 35px 65px;
	}
	
	.footer-inner {
		max-width: 1200px;
		margin: 0 auto 60px;
		display: flex;
		justify-content: space-between;
		
		&__logo {
			width: 23.75%;
			
			&__address {
				font-size: 1.5rem;
				margin: 20px 0 15px;
				
				@media screen and (max-width: 1260px) {
					font-size: $xs;
				}
				
				a[href^="tel:"] {
					color: $white;
					font-weight: normal;
				}
			}
			
			&__sns {
				display: flex;
				
				&__btn {
					margin: 0 5px 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 3.0rem;
					line-height: 1.0;
					
					&--twitter {
						padding: 7.5px;
					}	
					
					&__link {
						text-decoration: none;
						color: $white;
					}
				}
			}
		}
		
		&__fnav {
			display: flex;
			width: 66.67%;
			
			&__item {
				width: 50%;
				border-left: 1px solid rgba($white, 0.3);
				padding: 0 20px 0 40px;
				
				@media screen and (max-width: 1260px) {
					padding: 0 20px;
				}
				
				&__link {
					display: block;
					color: $white;
					text-decoration: none;
					font-size: 1.7rem;
					font-weight: bold;
					margin: 0 0 5px;
					@include transition;
					
					&:hover {
						.header-inner {
							&__fnav {
								&__item {
									&__text {
										text-decoration: underline;
									}
								}
							}
						}
					}
					
					@media screen and (max-width: 1260px) {
						font-size: 1.5rem;
					}
					
					&__ico {
						margin: 0 10px 0 0;
					}
				}
			}
		}
	}
	
	.copyright {
		text-align: center;
		font-size: 1.5rem;
		color: rgba($white, 0.7);
		
		@media screen and (max-width: 1260px) {
			font-size: $s;
		}
	}
}